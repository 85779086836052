import { template as template_43458997159a493bad29f90e6b683e17 } from "@ember/template-compiler";
const FKText = template_43458997159a493bad29f90e6b683e17(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
