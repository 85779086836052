import { template as template_cf9d0a3b5ed04a95a7a34595acb2e500 } from "@ember/template-compiler";
const WelcomeHeader = template_cf9d0a3b5ed04a95a7a34595acb2e500(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
