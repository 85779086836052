import { template as template_e6d6d25c8c0a45efb2a923d1c0285f8e } from "@ember/template-compiler";
const FKControlMenuContainer = template_e6d6d25c8c0a45efb2a923d1c0285f8e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
